import React, { useReducer, createContext } from 'react';

const defaultState = {
  showNavigation: true,
};

export const GlobalContext = createContext(defaultState);

export const ContextActions = {
  TOGGLE_SIDEBAR: 'TOGGLE_SIDEBAR',
  SET_SIDEBAR: 'SET_SIDEBAR',
};

const reducer = (state, action) => {
  const newState = { ...state };
  switch (action.type) {
    case ContextActions.TOGGLE_SIDEBAR: {
      newState.showNavigation = !state.showNavigation;
      return newState;
    }
    case ContextActions.SET_SIDEBAR: {
      newState.showNavigation = action.payload;
      return newState;
    }
    default: {
      return state;
    }
  }
};

export const ContextProvider = ({ children }) => {
  const [globalState, dispatch] = useReducer(reducer, defaultState);

  const value = {
    globalState,
    toggleSideBar: () => dispatch({ type: ContextActions.TOGGLE_SIDEBAR }),
    setSideBar: (payload) => dispatch({ type: ContextActions.SET_SIDEBAR, payload }),
  };

  return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>;
};
