/**
 * Implement Gatsby's Browser APIs in this file.
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import 'typeface-roboto';
import React from 'react';
import { ContextProvider } from './src/context';
import { Layout } from './src/components/layout';

// wrapp entire app with context store
export const wrapRootElement = ({ element }) => {
  return <ContextProvider>{element}</ContextProvider>;
};

// wrap all pages with layout; includes header and sidebar
export const wrapPageElement = ({ element, props }) => {
  return <Layout>{element}</Layout>;
};
