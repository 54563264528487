/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { useContext } from 'react';
import { Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Sidebar } from './sidebar';
import CssBaseline from '@material-ui/core/CssBaseline';
import bgImg from '../images/colorado-bg-1-dark.jpg';
import MenuIcon from '@material-ui/icons/Menu';
import { GlobalContext } from '../context';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
  },
  sidebar: {
    height: '100vh',
    overflowY: 'auto',
    backgroundImage: `url("${bgImg}")`,
    backgroundColor: 'grey',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    position: 'fixed',
    boxShadow: theme.shadows[10],
    zIndex: theme.zIndex.modal,
  },
  main: {
    padding: theme.spacing(4),
    position: 'relative',
    overflowX: 'hidden',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  menuBtn: {
    padding: '10px 10px 20px 20px',
    position: 'fixed',
    right: '0px',
    top: '0px',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));

export const Layout = ({ children, ...rest }) => {
  const { root, sidebar, main, menuBtn } = useStyles();
  const { toggleSideBar } = useContext(GlobalContext);

  return (
    <div {...rest}>
      <CssBaseline />
      <Grid container className={root}>
        <Grid item className={sidebar} xs={12} sm={5} md={4}>
          <Sidebar key='super-side-bar' />
        </Grid>
        <Grid item xs={12} sm={5} md={4}></Grid>
        <Grid item className={main} xs={12} sm={7} md={8}>
          <Button
            className={menuBtn}
            startIcon={<MenuIcon style={{ fontSize: '36px' }} />}
            size='large'
            onClick={toggleSideBar}
          />
          {children}
        </Grid>
      </Grid>
    </div>
  );
};

export default Layout;
