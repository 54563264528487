import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Box,
  Avatar,
  Typography,
  List,
  ListItem,
  Link,
} from "@material-ui/core";
import profilePic from "../images/trevor-profile-pic.jpg";
import TwitterIcon from "@material-ui/icons/Twitter";
import GitHubIcon from "@material-ui/icons/GitHub";
import EmailIcon from "@material-ui/icons/Email";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import { GlobalContext } from "../context";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { navigate } from "gatsby";
import { motion } from "framer-motion";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    zIndex: theme.zIndex.modal,
    padding: theme.spacing(5, 3),
    [theme.breakpoints.down("xs")]: {
      paddingTop: "10px",
    },
  },
  intro: {
    textAlign: "right",
    color: "#FAFAFA",
    textShadow: "1px 1px 2px rgba(0,0,0,0.5)",
    paddingBottom: "5vh",
  },
  introTitle: {
    ...theme.typography.h4,
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      ...theme.typography.h5,
    },
    [theme.breakpoints.down("xs")]: {
      ...theme.typography.h4,
    },
  },
  introText: {
    lineHeight: 1.5,
    [theme.breakpoints.down("sm")]: {
      ...theme.typography.h6,
    },
  },
  avatar: {
    height: "200px",
    width: "200px",
    margin: "0 0 20px auto",
    boxShadow: theme.shadows[10],
    [theme.breakpoints.down("sm")]: {
      width: "150px",
      height: "150px",
    },
  },
  navListItem: {
    color: "#FAFAFA",
    justifyContent: "flex-end",
  },
  svg: {
    fill: "#FAFAFA",
  },
  nav: {
    paddingBottom: "7vh",
  },
}));

const container = {
  hidden: {},
  visible: {
    transition: {
      duration: 1,
      staggerChildren: 0.05,
      staggerDirection: -1,
    },
  },
};
const item = {
  hidden: {
    opacity: 0,
    y: -50,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.33,
    },
  },
};

export const Sidebar = () => {
  const {
    root,
    avatar,
    intro,
    nav,
    navListItem,
    svg,
    introText,
    introTitle,
  } = useStyles();
  const { globalState, toggleSideBar } = useContext(GlobalContext);
  const theme = useTheme();
  const isSmartPhone = useMediaQuery(theme.breakpoints.down("xs"));

  if (isSmartPhone && !globalState.showNavigation) {
    return null;
  }

  const goTo = (url) => {
    toggleSideBar();
    navigate(url);
  };

  return (
    <Box className={root}>
      <motion.div variants={container} initial="hidden" animate="visible">
        {/* ==== INTRO ==== */}
        <Box className={intro}>
          <motion.div variants={item}>
            <Avatar
              alt="Trevor Blackmans profile picture"
              src={profilePic}
              className={avatar}
            />
          </motion.div>
          <motion.div variants={item}>
            <Typography component="h1" className={introTitle}>
              Trevor Blackman
            </Typography>
          </motion.div>
          <motion.div variants={item}>
            <Typography variant="h5" className={introText}>
              I'm a Colorado based web developer, building simple, fast, and
              user friendly web apps!
            </Typography>
          </motion.div>
        </Box>
        {/* ==== NAV ==== */}
        <motion.div variants={item}>
          <Box className={nav}>
            <List component="nav">
              <ListItem
                button
                className={navListItem}
                onClick={() => goTo("/")}
              >
                <Typography
                  variant="h6"
                  component="p"
                  style={{ textAlign: "right" }}
                >
                  Home
                </Typography>
              </ListItem>
              <ListItem
                button
                className={navListItem}
                onClick={() => goTo("/about")}
              >
                <Typography
                  variant="h6"
                  component="p"
                  style={{ textAlign: "right" }}
                >
                  About
                </Typography>
              </ListItem>
              <ListItem
                button
                className={navListItem}
                onClick={() => goTo("/projects")}
              >
                <Typography
                  variant="h6"
                  component="p"
                  style={{ textAlign: "right" }}
                >
                  Projects
                </Typography>
              </ListItem>
              <ListItem
                button
                className={navListItem}
                onClick={() => goTo("/contact")}
              >
                <Typography
                  variant="h6"
                  component="p"
                  style={{ textAlign: "right" }}
                >
                  Contact Me
                </Typography>
              </ListItem>
            </List>
          </Box>
        </motion.div>
        {/* ==== FOOTER ==== */}
        <motion.div variants={item}>
          <Box>
            <Grid
              container
              justifyContent="flex-end"
              alignItems="center"
              spacing={4}
            >
              <Grid item>
                <Link
                  href="https://twitter.com/TrevorJBlackman"
                  target="_blank"
                >
                  <TwitterIcon className={svg} />
                </Link>
              </Grid>
              <Grid item>
                <Link
                  href="https://www.linkedin.com/in/tjblackman/"
                  target="_blank"
                >
                  <LinkedInIcon className={svg} />
                </Link>
              </Grid>
              <Grid item>
                <Link href="https://github.com/TJBlackman" target="_blank">
                  <GitHubIcon className={svg} />
                </Link>
              </Grid>
              <Grid item>
                <Link href="mailto:TJBlackman08@gmail.com" target="_blank">
                  <EmailIcon className={svg} />
                </Link>
              </Grid>
            </Grid>
          </Box>
        </motion.div>
      </motion.div>
    </Box>
  );
};
